import React from 'react'
import Layout from '../../../templates/layout'
import Seo from '../../../helpers/seo'
import CTA from '../../../components/organisms/CTA'
import CasesRelational from '../../../components/organisms/CasesRelational'
import Banner from '../../../components/molecules/Banner'
import Content from './content'
import content from './content.json'
import { isMobile } from 'react-device-detect'

const { relational } = content

export const SOMASquadsWeb = () => {
  return (
    <Layout page="SOMA Squads Web">
      <Banner
        alt="Reprodução e-commerce OFF PREMIUM - Grupo SOMA | Cliente Grupo SOMA"
        image={
          isMobile ? 'soma-squads-web-banner-mobile.webp' : 'soma-squads-web-banner.webp'
        }
      />
      <Content />
      <CTA />
      <CasesRelational relationalData={relational} itsRelationalCase />
    </Layout>
  )
}

export const Head = () => <Seo title="SOMA Squads Web" />

export default SOMASquadsWeb
